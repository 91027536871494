











































import dieta_visualizacionModule from "@/store/modules/dieta_visualizacion-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ListaCompraView extends Vue {
  public created() {
    dieta_visualizacionModule.getLastListaDeLaCompra();
  }
  public get items() {
    /* try {
      return dieta_visualizacionModule.lista_compra.sort(function (a, b) {
        if (!a.comprado) {
          return -1;
        }
        if (a.comprado) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
    } catch (error) {}*/
    return dieta_visualizacionModule.lista_compra;
  }
  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 150;
    return "height:" + h + "px;overflow: auto;";
  }
}
